import api from './api'
export default {
    addStatusPage(data){
        return api.execute(`POST`,`/statuspage`,data)
    },
    getAll(paginationQuery){
        return api.execute(`GET`,`/statuspage`+paginationQuery)
    },
    deleteStatus(id){
        return api.execute(`DELETE`,`/statuspage`,id)
    },
    updateStatusPage(data){
        return api.execute(`PUT`,`/statuspage`,data)
    },

}