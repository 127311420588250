<template>
  <div>
    <Breadcrumbs main="" title="Status Page" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 mt-1">
          <div slot="with-padding">
            <b-row class="sectionShowDesktop">
                <b-col class="input-group cus-search-input">
                  <div class="form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="custom-font-22 border-left-0"
                        v-model="filter"
                        placeholder="Type to Search"
                        type="search"
                      ></b-form-input>
                    </b-input-group>
									</div>
                </b-col>
                <b-col class="col-md-8 col-12 d-flex justify-content-end">
                  <b-form-group
                      label-cols="6"
                      label="Per page"
                      class="text-right custom-font-20 text-secondary border border-dark pr-0 pl-1 mr-2 b-r-8 d-inline-block"
                    >
                    <b-form-select
                      class="custom-font-22 text-dark border-0 b-r-8"
                      v-model="perPage"
                      :options="pageOptions"
                       @change="getAllStatus"
                    ></b-form-select>
                    </b-form-group>
                  <div>
                    <button class="btn mr-2 custom-font-24" :class="btn_class" @click="add_status_modal = true" >+ Add Status Page</button>
                  </div>
                  <add-monitor-button class="hideMonitorBtn"></add-monitor-button>
                </b-col>
              </b-row>
              <b-row class="sectionShowMobile">
                <b-col class="cus-search-input">
                  <div class="input-group form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="custom-font-22 border-left-0"
                        v-model="filter"
                        placeholder="Type to Search"
                        type="search"
                      ></b-form-input>
                    </b-input-group>
									</div>
                </b-col>
                <b-col class="mt-3">
                 <b-row class="d-flex justify-content-between">
                  <div class="col-6">
                    <b-form-group
                      label-cols="6"
                      label="Per page"
                      class="col-md-8 text-right custom-font-20 text-secondary border border-dark pr-0 pl-0 b-r-8 d-inline-block"
                    >
                    <b-form-select
                      class="custom-font-22 text-dark border-0 b-r-8"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getAllStatus"
                    ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="pr-2">
                    <button class="btn ml-0 custom-font-24 mr-2" :class="btn_class" @click="add_status_modal = true" >+ Add Status Page</button>
                  </div>
                 </b-row>
                </b-col>
              </b-row>
     
                <div class="table-responsive datatable-vue text-left custom-font-18">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <template #cell(name)="data">
                  <span class="text-primary"><a :href="statusPageurl+data.item._id" target="_blank" title="View Status Page" rel="noopener noreferrer">{{ data.item.name }}</a></span>
                </template>
                <template #cell(pagePassword)="data">
                   ************
                </template>
                <template #cell(homepageurl)="data">
                  <span class="text-primary">{{ data.item.homepageurl }}</span>
                </template>
                <template #cell(customDomain)="data">
                  <span class="text-primary">{{ data.item.customDomain }}</span>
                </template>
                <template #cell(createdAt)="data">
                  <span>{{ changeDateFormate(data.item.createdAt) }}</span>
                </template>
                
                <template #cell(status)="data">
                    <div class="media-body switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.status @change="statusUpdate(data.item)"><span class="switch-state" :class="data.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template>
                <template #cell(action)="data">
                  <feather class="text-danger" type="trash-2" title="Delete" @click="deleteStatus(data.item._id)" style="cursor: pointer;"></feather>
                  <feather class="text-dark ml-2" type="edit" title="Edit" @click="editStatus(data.item)" style="cursor: pointer;"></feather>         
                  <a :href="statusPageurl+data.item._id" target="_blank" title="View Status Page" rel="noopener noreferrer"><feather class="text-dark ml-2" type="eye"></feather>  </a>       
                </template> 
                </b-table>
              </div>
             
              <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getAllStatus('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="items.length >= perPage" @click="getAllStatus('next')">Next &raquo;</a>
                </div>
              </b-col>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="status-monitor" size="lg" title="Public Status Page Information" class="theme-modal" @hide="closeModal" v-model="add_status_modal" no-close-on-backdrop hide-footer>
        <add-status-page @statusmodal="closeModal" :add_status_modal="add_status_modal" :edit_status="edit_status" :status_data="status_data"></add-status-page>
    </b-modal>
  </div>
</template>



<script>
import moment from 'moment'
import AddStatusPage from './AddStatusPage.vue';
import StatusPageController from "../../services/statuspageController";
import Config from "../../services/config"
import AddMonitorButton from ".././components/addMonitorButton.vue";
export default{
  components: { AddStatusPage, AddMonitorButton },
    name:'StatusPage',
    data(){
        return{
            add_status_modal:false,
            items:[],
            totalRows:0,
            perPage: 10,
            pageOptions: [5, 10, 50],
            currentPage: 1,
            filter:null,
            edit_status:false,
            tablefields:[
              { key: "name", label: "Name", sortable: true },
              // { key: "homepageurl", label: "Home Page Url", sortable: true },
              // { key: "customDomain", label: "Custom Domain", sortable: true },
              { key: "status", label: "Status", sortable: false },
              { key: "pagePassword", label: "Page password", sortable: false },
              { key: "createdAt", label: "Created At", sortable: true },
              { key: "action", label: "Action", sortable: false },
            ],
            status_data:{},
            statusPageurl:'',
            btn_class:''
        }
    },
    mounted(){
      this.statusPageurl = Config.statusPageUrl
      this.getAllStatus()

      let pathName = this.$route.name
      if (pathName === 'Dashboard' || pathName === 'Incidents' || pathName === 'SubUser' || pathName === 'Profile') {
          this.btn_class = 'custom-btn-dark';
      } else {
          this.btn_class = 'add-monitor-btn-green';
      }
    },
    methods:{
      closeModal(){
          this.add_status_modal = false
          this.edit_status = false;
          this.getAllStatus();
        },
      async getAllStatus(txt){
        this.items = []
        if(txt == 'next'){this.currentPage ++}
        if(txt == 'prev'){
          if(this.currentPage != 1){
          this.currentPage --
          }
        }
        let paginationQuery = '?currentPage='+this.currentPage+'&pageSize='+this.perPage

        let response = await StatusPageController.getAll(paginationQuery)
        if (response.result) {
            this.items = response.data;
            this.totalRows = this.items.length;
        }
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async deleteStatus(id){
        if(confirm('Are you sure to delete this status?')){
        let response = await StatusPageController.deleteStatus({"spid": id});
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.getAllStatus()
          }
          else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
          }
      }
      },
      changeDateFormate(d){
          return moment(d).format('D/M/YYYY, hh:mm A')
      },
      editStatus(data){
          this.add_status_modal = false
          this.add_status_modal = true
          this.status_data = data;
          this.edit_status = true;
      },
      async statusUpdate(data){
        // this.loading = true;
        var s = true
        if(data.status == true){
            s = false
        }
        if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
        var payload = {"spid": data._id, "changePayload": {"status":s}}
        let response = await StatusPageController.updateStatusPage(payload)
        if(response.result){
          let toastMessage = data.status ? "Activated successfully" : "Deactivated successfully";
          this.$toasted.show(toastMessage || response.message, { 
              theme: 'outline', 
              position: "bottom-center", 
              type: 'success', 
              duration: 2000 
          });
        }
        else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getAllStatus()
        }
      },
  }
}
</script>

<style scoped>
.sectionShowMobile {
 display: none !important;
}


@media screen and (max-width: 768px) {
  .sectionShowMobile {
    display: block !important;
  }

  .sectionShowDesktop {
    display: none !important;
  }

}
</style>